import React, { useState, useEffect } from 'react'

import { useToast, Text, Stack, Box, Divider } from '@chakra-ui/react'
import { LineChart, XAxis, CartesianGrid, Line, Tooltip, ResponsiveContainer } from 'recharts'

import actions from '../actions'
import { showErrorToast } from '../utils'

function Upcoming() {
  const [chart, setChart] = useState({})

  const [, setLoading] = useState(false)

  const toast = useToast()

  const fetchItems = async () => {
    setLoading(true)
    try {
      const { chart: ch = {} } = await actions.fetchGoals()
      setChart(ch)
    } catch (err) {
      showErrorToast(toast, err.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchItems()
    }

    fetchData()
  }, [])

  return (
    <Box maxW="xl" mx="auto" mt={5} p={5}>
      <Stack w="100%">
        <Box py={5} w="100%">
          <Text fontWeight="black" fontSize="xl">
            Goal Progress For November
          </Text>
          <Text>24k Discord Members</Text>
          <Text>$15k Revenue</Text>

          {chart && chart.data && (
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={chart.data}>
                <XAxis minTickGap={40} dataKey="name" />
                <Tooltip />
                <CartesianGrid stroke="#f5f5f5" />
                <Line type="linear" dataKey="users" stroke="#805AD5" yAxisId={0} />
                <Line
                  dot={false}
                  type="monotone"
                  dataKey="users_goal"
                  stroke="#805AD5"
                  strokeDasharray="3 7"
                  yAxisId={0}
                />
                <Line type="linear" dataKey="revenue" stroke="#38A169" yAxisId={0} />
                <Line
                  dot={false}
                  type="monotone"
                  dataKey="revenue_goal"
                  stroke="#38A169"
                  strokeDasharray="3 7"
                  yAxisId={0}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Stack>
      <Divider />
    </Box>
  )
}

export default Upcoming
