import React from 'react'

import { Box, Heading, Flex, Button, Text } from '@chakra-ui/react'
import { useLocation, Link } from 'react-router-dom'

function Header({ handleLogout, authState, onRouteChange, profile, ...props }) {
  const location = useLocation()

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="purple.500"
      backgroundImage="linear-gradient(135deg,#6c33da,#a431ff)"
      color="white"
      {...props}
    >
      <Flex align="center" mr={5} mt={3}>
        <Heading as="h1" size="lg">
          🦄 buildspace admin
        </Heading>
      </Flex>

      <Box>
        <Link to="/courses">
          <Button
            mt={3}
            mr={3}
            p={[2, 4]}
            bg={location.pathname.startsWith('/courses') ? 'white' : 'transparent'}
            border={location.pathname.startsWith('/courses') ? 'none' : '1px'}
            borderColor={location.pathname.startsWith('/courses') ? 'none' : 'white'}
            color={location.pathname.startsWith('/courses') ? 'black' : 'white'}
            fontWeight={location.pathname.startsWith('/courses') ? 'bold' : 'normal'}
            rounded="full"
          >
            <Text fontSize={['xs', 'md']}>Projects</Text>
          </Button>
        </Link>
        <Link to="/jobs">
          <Button
            mt={3}
            mr={3}
            p={[2, 4]}
            bg={location.pathname.startsWith('/jobs') ? 'white' : 'transparent'}
            border={location.pathname.startsWith('/jobs') ? 'none' : '1px'}
            borderColor={location.pathname.startsWith('/jobs') ? 'none' : 'white'}
            color={location.pathname.startsWith('/jobs') ? 'black' : 'white'}
            fontWeight={location.pathname.startsWith('/jobs') ? 'bold' : 'normal'}
            rounded="full"
          >
            <Text fontSize={['xs', 'md']}>Jobs</Text>
          </Button>
        </Link>
        <Link to="/goals">
          <Button
            mt={3}
            mr={3}
            p={[2, 4]}
            bg={location.pathname.startsWith('/goals') ? 'white' : 'transparent'}
            border={location.pathname.startsWith('/goals') ? 'none' : '1px'}
            borderColor={location.pathname.startsWith('/goals') ? 'none' : 'white'}
            color={location.pathname.startsWith('/goals') ? 'black' : 'white'}
            fontWeight={location.pathname.startsWith('/goals') ? 'bold' : 'normal'}
            rounded="full"
          >
            <Text fontSize={['xs', 'md']}>Goals</Text>
          </Button>
        </Link>
        <Link to="/companies">
          <Button
            mt={3}
            mr={3}
            p={[2, 4]}
            bg={location.pathname.startsWith('/companies') ? 'white' : 'transparent'}
            border={location.pathname.startsWith('/companies') ? 'none' : '1px'}
            borderColor={location.pathname.startsWith('/companies') ? 'none' : 'white'}
            color={location.pathname.startsWith('/companies') ? 'black' : 'white'}
            fontWeight={location.pathname.startsWith('/companies') ? 'bold' : 'normal'}
            rounded="full"
          >
            <Text fontSize={['xs', 'md']}>Companies</Text>
          </Button>
        </Link>
      </Box>
    </Flex>
  )
}

export default Header
