import React, { useState, useEffect, useRef, useCallback } from 'react'

import {
  useToast,
  Text,
  Stack,
  Box,
  Divider,
  Spinner,
  Button,
  Badge,
  Textarea,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import actions from '../actions'
import { showErrorToast } from '../utils'

function Lesson() {
  const [lesson, setLesson] = useState({})

  const editorRef = useRef(null)

  const [loading, setLoading] = useState(false)

  const toast = useToast()
  const { course_id, lesson_id } = useParams()

  const fetchItems = async () => {
    setLoading(true)
    try {
      const { lesson: l = {} } = await actions.fetchLesson(course_id, lesson_id)
      setLesson(l)
    } catch (err) {
      showErrorToast(toast, err.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchItems()
    }

    fetchData()
  }, [])

  const onUpdateContent = async () => {
    setLoading(true)
    const content = editorRef.current.value
    try {
      await actions.updateLesson(course_id, lesson_id, {
        content,
        contentType: 'markdown',
      })
      await fetchItems()
    } catch (err) {
      showErrorToast(toast, err.message)
    }
    setLoading(false)
  }

  const onRefChange = useCallback(
    node => {
      if (node === null) {
        // DOM node referenced by ref has been unmounted
      } else {
        // DOM node referenced by ref has changed and exists
        editorRef.current = node
        editorRef.current.value = lesson.content
      }
    },
    [lesson]
  ) // adjust deps

  return (
    <Box maxW="4xl" mx="auto" mt={5} p={5}>
      <Text fontWeight="bold" fontSize="2xl">
        {lesson.title}
      </Text>
      {lesson.type && (
        <Badge my={2} py={1} px={3} rounded="full" bg={lesson.type.color}>
          {lesson.type.title}
        </Badge>
      )}
      <Divider />
      <Stack>
        <Box>
          <Textarea ref={onRefChange} minH="700px" />

          {lesson.type && lesson.type.title === 'course' && (
            <Box p={3} my={2} w="100%" bg="yellow.100">
              <Text fontWeight="medium">
                ⚠️ Updating content in this lesson will update across all cohorts
              </Text>
            </Box>
          )}
          <Button
            h="50px"
            colorScheme="purple"
            fontSize="lg"
            my={2}
            w="100%"
            isLoading={loading}
            onClick={onUpdateContent}
          >
            Update Content
          </Button>
        </Box>
      </Stack>

      {loading && !lesson.id && (
        <Box d="flex" my={4} justifyContent="center">
          <Spinner color="purple.500" />
        </Box>
      )}
      <Divider />
    </Box>
  )
}

export default Lesson
