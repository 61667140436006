import { API } from 'aws-amplify'

const fetchQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search)
  let retVal
  // Display the key/value pairs
  // eslint-disable-next-line no-restricted-syntax
  for (const pair of searchParams.entries()) {
    if (!retVal) retVal = { [pair[0]]: pair[1] }
    // eslint-disable-next-line prefer-destructuring
    else retVal[pair[0]] = pair[1]
  }

  return retVal
}

const fetchCourses = async () => {
  const params = {
    queryStringParameters: fetchQueryParams(),
  }

  try {
    const { result = {} } = await API.get('BuildspaceAdminAPI', '/courses', params)

    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const fetchCompanies = async () => {
  const params = {
    queryStringParameters: fetchQueryParams(),
  }

  try {
    const { result = {} } = await API.get('BuildspaceAdminAPI', '/companies', params)

    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const fetchCourse = async course_id => {
  const params = {
    queryStringParameters: fetchQueryParams(),
  }

  try {
    const { result = {} } = await API.get('BuildspaceAdminAPI', `/courses/${course_id}`, params)
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const fetchUser = async user_id => {
  const params = {
    queryStringParameters: fetchQueryParams(),
  }

  try {
    const { result = {} } = await API.get('BuildspaceAdminAPI', `/users/${user_id}`, params)
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const fetchLesson = async (course_id, lesson_id) => {
  const params = {
    queryStringParameters: fetchQueryParams(),
  }

  try {
    const { result = {} } = await API.get(
      'BuildspaceAdminAPI',
      `/courses/${course_id}/lessons/${lesson_id}`,
      params
    )
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const fetchCohort = async (course_id, cohort_id) => {
  const params = {
    queryStringParameters: fetchQueryParams(),
  }

  try {
    const { result = {} } = await API.get(
      'BuildspaceAdminAPI',
      `/courses/${course_id}/cohorts/${cohort_id}`,
      params
    )
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const fetchGoals = async () => {
  const params = {
    queryStringParameters: fetchQueryParams(),
  }

  try {
    const { result = {} } = await API.get('BuildspaceAdminAPI', `/goals`, params)
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const createLesson = async (course_id, data = {}) => {
  const params = {
    queryStringParameters: fetchQueryParams(),
    body: data,
  }

  try {
    const { result = {} } = await API.post(
      'BuildspaceAdminAPI',
      `/courses/${course_id}/lessons`,
      params
    )
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const updateLesson = async (course_id, lesson_id, data = {}) => {
  const params = {
    queryStringParameters: fetchQueryParams(),
    body: data,
  }

  try {
    const { result = {} } = await API.put(
      'BuildspaceAdminAPI',
      `/courses/${course_id}/lessons/${lesson_id}`,
      params
    )
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const recalculateMerkleRoot = async (course_id, cohort_id) => {
  const params = {
    queryStringParameters: fetchQueryParams(),
    body: { action: 'merkleroot' },
  }

  try {
    const { result = {} } = await API.put(
      'BuildspaceAdminAPI',
      `/courses/${course_id}/cohorts/${cohort_id}`,
      params
    )
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const createExport = async (data = {}) => {
  const params = {
    queryStringParameters: fetchQueryParams(),
    body: data,
  }

  try {
    const { result = {} } = await API.post('BuildspaceAdminAPI', `/export`, params)
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const updateCourse = async (course_id, data = {}) => {
  const params = {
    queryStringParameters: fetchQueryParams(),
    body: data,
  }

  try {
    const { result = {} } = await API.put('BuildspaceAdminAPI', `/courses/${course_id}`, params)
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const updateUser = async (user_id, data = {}) => {
  const params = {
    queryStringParameters: fetchQueryParams(),
    body: data,
  }

  try {
    const { result = {} } = await API.put('BuildspaceAdminAPI', `/users/${user_id}`, params)
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const updateIntro = async (user_id, intro_id, data = {}) => {
  const params = {
    queryStringParameters: fetchQueryParams(),
    body: data,
  }

  try {
    const { result = {} } = await API.put(
      'BuildspaceAdminAPI',
      `/users/${user_id}/intros/${intro_id}`,
      params
    )
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const createIntro = async (user_id, company_id) => {
  const params = {
    queryStringParameters: fetchQueryParams(),
    body: { company_id },
  }

  try {
    const { result = {} } = await API.post('BuildspaceAdminAPI', `/users/${user_id}/intros`, params)
    return result
  } catch (err) {
    const errData = err.response ? err.response.data : err
    throw errData
  }
}

const actions = {
  fetchCourses,
  fetchCourse,
  createLesson,
  fetchLesson,
  updateLesson,
  updateCourse,
  fetchCohort,
  createExport,
  recalculateMerkleRoot,
  fetchUser,
  updateUser,
  createIntro,
  updateIntro,
  fetchGoals,
  fetchCompanies,
}

export default actions
