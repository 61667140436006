import React from 'react'

import { Box } from '@chakra-ui/react'
import { Route, Switch } from 'react-router-dom'

import Home from './Home'
import User from './User'

function Jobs() {
  return (
    <Box maxW="5xl" mx="auto" mt={5} p={5}>
      <Switch>
        <Route exact path="/jobs">
          <Home />
        </Route>
        <Route exact path="/jobs/:user_id">
          <User />
        </Route>
      </Switch>
    </Box>
  )
}

export default Jobs
