/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback, useRef } from 'react'

import {
  useToast,
  Text,
  Stack,
  Box,
  ButtonGroup,
  Button,
  Icon,
  Input,
  Badge,
  HStack,
  VStack,
} from '@chakra-ui/react'
import algoliasearch from 'algoliasearch/lite'
import { FaGithub, FaLinkedin, FaSafari, FaTwitter } from 'react-icons/fa'
import {
  InstantSearch,
  Hits,
  connectSearchBox,
  Pagination,
  RefinementList,
} from 'react-instantsearch-dom'

import actions from '../actions'
import { convertTZ, showErrorToast } from '../utils'

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
)

function Jobs() {
  const inputRef = useRef(null)
  const [rankOverride, setRankOverride] = useState({})
  const [loading, setLoading] = useState({})
  const toast = useToast()

  function SearchBox({ currentRefinement, refine }) {
    return (
      <HStack w="100%">
        <Input
          placeholder="Search here..."
          h="50px"
          w="100%"
          type="search"
          value={currentRefinement}
          onChange={event => {
            refine(event.currentTarget.value)
            inputRef.current = event.currentTarget.value
          }}
        />
        {currentRefinement && (
          <Button
            onClick={e => {
              e.preventDefault()
              navigator.clipboard.writeText(
                `https://pro.buildspace.so/home?q=${encodeURIComponent(inputRef.current)}`
              )
            }}
            h="50px"
          >
            Copy
          </Button>
        )}
      </HStack>
    )
  }

  const CustomSearchBox = connectSearchBox(SearchBox)

  const updateBuildspaceRank = useCallback(
    async (user_id, rank) => {
      setLoading(prevLoading => {
        prevLoading[user_id] = true
        return { ...prevLoading }
      })

      try {
        await actions.updateUser(user_id, {
          action: 'bs_rank',
          data: { rank },
        })

        setRankOverride(prevOverride => {
          prevOverride[user_id] = rank
          return { ...prevOverride }
        })
      } catch (err) {
        showErrorToast(toast, err.message)
      }

      setLoading(prevLoading => {
        prevLoading[user_id] = false
        return { ...prevLoading }
      })
    },
    [toast]
  )

  const renderExperiences = hit => {
    const noItemsPresent = (
      <Text fontSize="sm" w="100%" textAlign="center" color="gray.400">
        No work or project experience added
      </Text>
    )

    if (!hit.exp_companies) {
      return noItemsPresent
    }

    if (hit.exp_companies.length === 0) {
      return noItemsPresent
    }

    const workItems = []
    const projectItems = []
    hit.exp_companies.forEach(item => {
      if (item.type === 'work') {
        workItems.push({ id: item.id, title: item.work_company })
      }

      if (item.type === 'project') {
        projectItems.push({ id: item.id, title: item.project_title, url: item.project_url })
      }
    })

    return (
      <VStack>
        <Text fontSize="sm" fontWeight="bold">
          Work
        </Text>
        <HStack>
          {workItems.map(item => (
            <Badge rounded="md" m={2} p={2} key={item.id}>
              {item.title}
            </Badge>
          ))}
        </HStack>
        <Text fontSize="sm" fontWeight="bold">
          Projects
        </Text>
        <HStack>
          {projectItems.map(item => (
            <Badge rounded="md" m={2} p={2} key={item.id}>
              <a href={item.url} target="_blank" rel="noreferrer">
                {item.title}
              </a>
            </Badge>
          ))}
        </HStack>
      </VStack>
    )
  }

  const hit = item => {
    const { hit, objectID } = item

    return (
      <Box textAlign="left" w="100%" key={objectID}>
        {hit.date && (
          <Text color="gray.500" fontSize="xs" position="absolute" right="0" bottom="0">
            {convertTZ(hit.date * 1000)}
          </Text>
        )}
        <HStack w="100%">
          <Stack w="50%" spacing={1}>
            <HStack spacing={1}>
              {/* TODO: Change this to proper env url :) */}
              <a
                href={`https://app.buildspace.so/profile/${hit.user_id}`}
                target="_blank"
                w="100%"
                rel="noreferrer"
              >
                <Text fontSize="sm" fontWeight="bold">
                  {hit.first_name}
                </Text>
              </a>
              {hit.is_location_public && (
                <>
                  <Text color="gray.400">|</Text>
                  <Text fontSize="xs" color="gray.500">
                    {hit.location}
                  </Text>
                </>
              )}
            </HStack>

            <Stack spacing={0}>
              <Text fontSize="xs" fontWeight="medium">
                {hit.bio}
              </Text>
            </Stack>
            <HStack mt={2} spacing={2}>
              {hit.linkedin && (
                <Button
                  bg="purple.400"
                  colorScheme="purple"
                  size="xs"
                  onClick={() => window.open(hit.linkedin)}
                >
                  <Icon color="white" as={FaLinkedin} />
                </Button>
              )}
              {hit.github && (
                <Button
                  bg="purple.400"
                  colorScheme="purple"
                  size="xs"
                  onClick={() => window.open(hit.github)}
                >
                  <Icon color="white" as={FaGithub} />
                </Button>
              )}
              {hit.website && (
                <Button
                  bg="purple.400"
                  colorScheme="purple"
                  size="xs"
                  onClick={() => window.open(hit.website)}
                >
                  <Icon color="white" as={FaSafari} />
                </Button>
              )}
              {hit.twitter && (
                <Button
                  bg="purple.400"
                  colorScheme="purple"
                  size="xs"
                  onClick={() => window.open(hit.twitter)}
                >
                  <Icon color="white" as={FaTwitter} />
                </Button>
              )}
            </HStack>

            <Stack>
              <Box my={2} w="100%" h="1px" bg="gray.300" />
            </Stack>
            {hit.rank !== -1 && (
              <Box>
                <ButtonGroup w="100%" size="sm" isAttached>
                  <Button
                    w="33%"
                    bg={hit.rank === 0 || rankOverride[hit.objectID] === 0 ? 'red.500' : 'white'}
                    color={hit.rank === 0 || rankOverride[hit.objectID] === 0 ? 'white' : 'black'}
                    colorScheme="red"
                    isLoading={loading[hit.objectID]}
                    borderWidth="1px"
                    fontWeight="medium"
                    onClick={() => updateBuildspaceRank(hit.objectID, 0)}
                  >
                    Low
                  </Button>
                  <Button
                    w="33%"
                    bg={hit.rank === 1 || rankOverride[hit.objectID] === 1 ? 'yellow.500' : 'white'}
                    color={hit.rank === 1 || rankOverride[hit.objectID] === 1 ? 'white' : 'black'}
                    colorScheme="yellow"
                    isLoading={loading[hit.objectID]}
                    borderWidth="1px"
                    fontWeight="medium"
                    onClick={() => updateBuildspaceRank(hit.objectID, 1)}
                  >
                    Med
                  </Button>
                  <Button
                    w="33%"
                    bg={hit.rank === 2 || rankOverride[hit.objectID] === 2 ? 'green.500' : 'white'}
                    color={hit.rank === 2 || rankOverride[hit.objectID] === 2 ? 'white' : 'black'}
                    colorScheme="green"
                    isLoading={loading[hit.objectID]}
                    borderWidth="1px"
                    fontWeight="medium"
                    onClick={() => updateBuildspaceRank(hit.objectID, 2)}
                  >
                    High
                  </Button>
                </ButtonGroup>
              </Box>
            )}
            <HStack mt={2} spacing={1}>
              {hit.rank && hit.rank === -1 && (
                <Badge
                  colorScheme={hit.rank === 2 ? 'green' : hit.rank === 1 ? 'yellow' : 'red'}
                  rounded="lg"
                  py={1}
                  px={2}
                  size="lg"
                >
                  {hit.rank}
                </Badge>
              )}
            </HStack>
            <HStack spacing={1}>
              <Text fontSize="xs">Public: {hit.is_public ? 'true' : 'false'}</Text>
              <Text color="gray.400">|</Text>
              <Text fontSize="xs">
                Yrs Exp: {hit.job_experience === 0 ? 'Student' : hit.job_experience}
              </Text>
            </HStack>
          </Stack>

          <HStack
            justifyContent="center"
            h="100%"
            w="50%"
            wrap="wrap"
            borderColor="gray.300"
            pl={4}
            spacing={2}
          >
            {renderExperiences(hit)}
          </HStack>
        </HStack>
      </Box>
    )
  }

  return (
    <Box maxW="5xl" mx="auto" mt={5} p={5}>
      <div className="ais-InstantSearch">
        <InstantSearch indexName={process.env.REACT_APP_ALGOLIA_INDEX} searchClient={searchClient}>
          <HStack spacing={4} alignItems="top">
            <Stack
              borderColor="gray.200"
              borderWidth="1px"
              p={3}
              bg="gray.50"
              rounded="lg"
              textAlign="left"
              spacing={4}
              fontSize="sm"
              w="17rem"
            >
              <Box>
                <Text fontWeight="bold">Roles</Text>
                <RefinementList attribute="roles" />
              </Box>
              <Box>
                <Text fontWeight="bold">Companies</Text>
                <RefinementList attribute="exp_companies.work_company" />
              </Box>
              <Box>
                <Text fontWeight="bold">Job Experience</Text>
                <RefinementList attribute="job_experience" />
              </Box>
              <Box>
                <Text fontWeight="bold">Country</Text>
                <RefinementList attribute="place.country" />
              </Box>
              <Box>
                <Text fontWeight="bold">Buildspace Rank</Text>
                <RefinementList attribute="rank" />
              </Box>
            </Stack>
            <Stack w="100%" alignItems="center">
              <CustomSearchBox />
              <Hits hitComponent={hit} />
              <Pagination />
            </Stack>
          </HStack>
        </InstantSearch>
      </div>
    </Box>
  )
}

export default Jobs
