/* eslint-disable no-return-await */
import React, { useState, useEffect } from 'react'

import {
  useToast,
  Text,
  Stack,
  Box,
  // Badge,
  Divider,
  Spinner,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  FormErrorMessage,
  Input,
  Modal,
  FormControl,
  ModalOverlay,
  ModalContent,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { LineChart, XAxis, CartesianGrid, Line, Tooltip, ResponsiveContainer } from 'recharts'

import actions from '../actions'
import { showErrorToast } from '../utils'

function Upcoming() {
  const [course, setCourse] = useState({})

  const [loading, setLoading] = useState({})
  const { handleSubmit, errors, register, formState } = useForm()
  const [createLessonModal, setCreateLessonModal] = useState(false)

  const toast = useToast()
  const { course_id } = useParams()

  const fetchItems = async () => {
    setLoading(prevLoading => {
      prevLoading.init = true
      return { ...prevLoading }
    })
    try {
      const { course: c = {} } = await actions.fetchCourse(course_id)
      setCourse(c)
    } catch (err) {
      showErrorToast(toast, err.message)
    }
    setLoading(prevLoading => {
      prevLoading.init = false
      return { ...prevLoading }
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchItems()
    }

    fetchData()
  }, [])

  const onCreateLesson = async (data, type) => {
    setLoading(true)
    try {
      data.type = type
      await actions.createLesson(course_id, data)
      await fetchItems()
      setCreateLessonModal(false)
    } catch (err) {
      showErrorToast(toast, err.message)
    }
    setLoading(false)
  }

  const createExport = async data => {
    setLoading(prevLoading => {
      prevLoading[data.action] = true
      return { ...prevLoading }
    })
    try {
      const { url } = await actions.createExport(data)
      if (url) {
        window.open(url)
      }
    } catch (err) {
      showErrorToast(toast, err.message)
    }
    setLoading(prevLoading => {
      prevLoading[data.action] = false
      return { ...prevLoading }
    })
  }

  const createReferralExport = async () => {
    await createExport({ action: 'referral', data: { course_id } })
  }

  const createBookingsExport = async () => {
    await createExport({ action: 'booking', data: { course_id } })
  }

  const validateId = value => {
    let error
    if (!value) {
      error = `Required`
    }
    return error || true
  }

  const renderCreateLessonModal = () => (
    <Modal
      size="lg"
      isOpen={createLessonModal}
      onClose={() => setCreateLessonModal(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Lesson</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(async data => await onCreateLesson(data))}>
            <FormControl mt={4} isInvalid={errors.title}>
              <Text fontWeight="bold">Title</Text>
              <Input
                name="title"
                placeholder="Lesson Title"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateId,
                })}
              />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.description}>
              <Text fontWeight="bold">Description</Text>
              <Input
                name="description"
                placeholder="Description"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register()}
              />
              <FormErrorMessage>
                {errors.description && errors.description.message}
              </FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            fontWeight="medium"
            mr={3}
            onClick={() => setCreateLessonModal(false)}
          >
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(async data => await onCreateLesson(data))}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

  // const renderLesson = (item = {}) => {
  //   const { title, id, link, type = {} } = item

  //   return (
  //     <Box key={id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
  //       <Stack spacing={2}>
  //         <Box fontWeight="bold">
  //           <Link to={link}>{title} </Link>
  //         </Box>
  //         <Box fontSize="xs" color="gray.600">
  //           <VStack>
  //             <HStack>
  //               <Text fontSize="xs" color="gray.600">
  //                 ID: {id}
  //               </Text>
  //               <Badge py={1} px={3} rounded="full" bg={type.color}>
  //                 {type.title}
  //               </Badge>
  //             </HStack>
  //           </VStack>
  //         </Box>
  //       </Stack>
  //     </Box>
  //   )
  // }

  const renderCohort = (item = {}) => {
    const { title, id, link } = item

    return (
      <Box key={id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Stack spacing={2}>
          <Box fontWeight="bold">
            <Link to={link}>{title} </Link>
          </Box>
          <Box fontSize="xs" color="gray.600">
            <Text>Cohort ID: {id}</Text>
          </Box>
        </Stack>
      </Box>
    )
  }

  // const renderSection = (item = {}) => {
  //   const { title, lessons = [] } = item

  //   return (
  //     <Box key={title} p={3}>
  //       <Text fontSize="xl" fontWeight="black">
  //         {title}
  //       </Text>
  //       <Divider />
  //       <Stack mt={2} spacing={2}>
  //         {lessons.map(renderLesson)}
  //       </Stack>
  //     </Box>
  //   )
  // }

  return (
    <Box maxW="xl" mx="auto" mt={5} p={5}>
      {createLessonModal && renderCreateLessonModal()}
      <Text fontWeight="bold" fontSize="2xl">
        {course.title}
      </Text>
      <Divider />
      <VStack py={5}>
        <HStack>
          <Text>Total Bookings:</Text>
          <Text fontWeight="bold">{course.bookings_count}</Text>
          <Button onClick={createBookingsExport} isLoading={loading.booking}>
            Export
          </Button>
        </HStack>

        <HStack>
          <Text>Total Referrals:</Text>
          <Text fontWeight="bold">{course.referrals_count}</Text>
          <Button onClick={createReferralExport} isLoading={loading.referral}>
            Export
          </Button>
        </HStack>
      </VStack>

      <Divider />

      <Stack w="100%">
        <Box py={5} w="100%">
          <Text fontWeight="black" fontSize="xl">
            Activity
          </Text>{' '}
          {course && course.chart && (
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={course.chart.data}>
                <XAxis minTickGap={40} dataKey="name" />
                <Tooltip />
                <CartesianGrid stroke="#f5f5f5" />
                <Line type="linear" dataKey="completions" stroke="#805AD5" yAxisId={0} />
                <Line type="linear" dataKey="impressions" stroke="#38A169" yAxisId={0} />
                <Line type="linear" dataKey="users" stroke="#3182CE" yAxisId={0} />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Stack>
      <Divider />

      <Box py={5}>
        {/* <Button
          fontWeight="bold"
          bg="gray.500"
          color="white"
          h="50px"
          fontSize="lg"
          style={{ text: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={() => setCreateLessonModal(true)}
        >
          <Icon mr={2} name="add" />
          Create Lesson
        </Button> */}
        <Box p={3}>
          <Text fontWeight="black" fontSize="xl">
            Cohorts
          </Text>
          <Divider />
          {course.cohorts && (
            <Stack mt={2} spacing={2}>
              {course.cohorts.map(renderCohort)}
            </Stack>
          )}

          {(!course.cohorts || course.cohorts.length === 0) && <Text>No cohorts</Text>}
        </Box>
      </Box>

      <Divider />

      <Box py={5}>
        {/* <Button
          fontWeight="bold"
          bg="gray.500"
          color="white"
          h="50px"
          fontSize="lg"
          style={{ text: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={() => setCreateLessonModal(true)}
        >
          <Icon mr={2} name="add" />
          Create Lesson
        </Button> */}

        {/* {course.sections && <Stack>{course.sections.map(renderSection)}</Stack>}

      {(!course.sections || course.sections.length === 0) && <Text>No sections</Text>} */}
      </Box>

      {loading.init && (
        <Box d="flex" my={4} justifyContent="center">
          <Spinner color="purple.500" />
        </Box>
      )}
      <Divider />
    </Box>
  )
}

export default Upcoming
