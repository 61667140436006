import React, { useState, useEffect } from 'react'

import { useToast, Text, Stack, Box, Divider, Spinner, Badge } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { LineChart, XAxis, CartesianGrid, Line, Tooltip, ResponsiveContainer } from 'recharts'

import actions from '../actions'
import { showErrorToast } from '../utils'

function Upcoming() {
  const [items, setItems] = useState([])
  const [chart, setChart] = useState([])
  const [members, setMembers] = useState('-')
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const fetchItems = async () => {
    setLoading(true)
    try {
      const { items: i = [], chart: ch = [], members: m } = await actions.fetchCourses()
      setItems(i)
      setChart(ch)
      setMembers(m)
    } catch (err) {
      showErrorToast(toast, err.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchItems()
    }

    fetchData()
  }, [])

  const renderItem = (item = {}) => {
    const { title, id, is_public } = item

    return (
      <Box key={id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Stack spacing={2}>
          {is_public && (
            <Text fontSize="sm" rounded="lg" bg="purple.100" p={2}>
              Drop In Class
            </Text>
          )}
          <Box fontWeight="bold">
            <Link to={`/courses/${id}`}>{title} </Link>
          </Box>
          <Box fontSize="xs" color="gray.600">
            <Text>Course ID: {id}</Text>
          </Box>
        </Stack>
      </Box>
    )
  }

  return (
    <Box maxW="xl" mx="auto" mt={5} p={5}>
      <Stack w="100%">
        <Box py={5} w="100%">
          <Text fontWeight="black" fontSize="xl">
            Activity
          </Text>{' '}
          {chart && chart.data && (
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={chart.data}>
                <XAxis minTickGap={40} dataKey="name" />
                <Tooltip />
                <CartesianGrid stroke="#f5f5f5" />
                <Line type="linear" dataKey="completions" stroke="#805AD5" yAxisId={0} />
                <Line type="linear" dataKey="impressions" stroke="#38A169" yAxisId={0} />
                <Line type="linear" dataKey="users" stroke="#3182CE" yAxisId={0} />
                <Line type="linear" dataKey="enrollments" stroke="#DD6B20" yAxisId={0} />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Stack>
      <Divider />

      {!loading && items.length === 0 && (
        <Box d="flex" my={4} justifyContent="center">
          <Text color="gray.500">No courses</Text>
        </Box>
      )}
      <Text mt={4} fontWeight="black" fontSize="xl">
        All Courses
      </Text>
      <Stack mt={2} spacing={2}>
        {items.map(renderItem)}
      </Stack>
      {loading && items.length === 0 && (
        <Box d="flex" my={4} justifyContent="center">
          <Spinner color="purple.500" />
        </Box>
      )}
      <Divider mt={4} />
      <Badge mt={4} rounded="full" p={2}>
        Discord Members: {members}
      </Badge>
    </Box>
  )
}

export default Upcoming
