import React, { useState, useEffect, useRef } from 'react'

import {
  useToast,
  Text,
  Stack,
  Box,
  Badge,
  Divider,
  Spinner,
  Button,
  Input,
  HStack,
  VStack,
  useClipboard,
} from '@chakra-ui/react'
import { Contract, providers } from 'ethers'
import { Link, useParams } from 'react-router-dom'
import { LineChart, XAxis, CartesianGrid, Line, Tooltip, ResponsiveContainer } from 'recharts'

import actions from '../actions'
import { showErrorToast, showSuccessToast } from '../utils'

function Upcoming() {
  const [course, setCourse] = useState({})
  const [cohort, setCohort] = useState({})
  const [contract, setContract] = useState(null)

  const [loading, setLoading] = useState({})

  const toast = useToast()
  const { onCopy, hasCopied } = useClipboard(cohort && cohort.merkleRoot)
  const { course_id, cohort_id } = useParams()

  const lessonARef = useRef()
  const lessonBRef = useRef()

  const fetchItems = async () => {
    setLoading(prevLoading => {
      prevLoading.init = true
      return { ...prevLoading }
    })
    try {
      const { course: c = {}, cohort: co = {} } = await actions.fetchCohort(course_id, cohort_id)
      setCourse(c)
      setCohort(co)
    } catch (err) {
      showErrorToast(toast, err.message)
    }
    setLoading(prevLoading => {
      prevLoading.init = false
      return { ...prevLoading }
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchItems()
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchContract = async () => {
      const { rpc_url, abi, address } = cohort.contract
      const provider = new providers.JsonRpcProvider(rpc_url)
      const contract = new Contract(address, abi, provider)

      const contractCohort = await contract.cohorts(cohort_id)
      setContract(contractCohort)
    }

    if (cohort.contract) {
      fetchContract()
    }
  }, [cohort_id, cohort])

  const createExport = async () => {
    setLoading(prevLoading => {
      prevLoading.exporting = true
      return { ...prevLoading }
    })
    try {
      const { url } = await actions.createExport({ action: 'cohort', data: { cohort_id } })
      if (url) {
        window.open(url)
      }
    } catch (err) {
      showErrorToast(toast, err.message)
    }
    setLoading(prevLoading => {
      prevLoading.exporting = false
      return { ...prevLoading }
    })
  }

  const createDropoffExport = async () => {
    setLoading(prevLoading => {
      prevLoading.dropoff = true
      return { ...prevLoading }
    })
    try {
      const { url } = await actions.createExport({
        action: 'dropoff',
        data: { lessons: [lessonARef.current.value, lessonBRef.current.value], cohort_id },
      })
      if (url) {
        window.open(url)
      }
    } catch (err) {
      showErrorToast(toast, err.message)
    }
    setLoading(prevLoading => {
      prevLoading.dropoff = false
      return { ...prevLoading }
    })
  }

  const updateMerkleRoot = async () => {
    setLoading(prevLoading => {
      prevLoading.merkle = true
      return { ...prevLoading }
    })

    try {
      await actions.recalculateMerkleRoot(course_id, cohort_id)
      await fetchItems()
      showSuccessToast(toast, `✅ Updated Merkle Root`)
    } catch (err) {
      showErrorToast(toast, err.message)
    }

    setLoading(prevLoading => {
      prevLoading.merkle = false
      return { ...prevLoading }
    })
  }

  const renderLesson = (item = {}) => {
    const { title, id, link, complete_pct = 0, chart, type = {}, date } = item

    return (
      <Box key={id} w="100%" border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Stack w="100%" spacing={2}>
          <Box fontWeight="bold">
            <Link to={link}>{title} </Link>
          </Box>
          <Box>
            <Text fontSize="md">Completions: {complete_pct}%</Text>
          </Box>
          <VStack>
            <HStack>
              <Text fontSize="xs" color="gray.600">
                ID: {id}
              </Text>
              <Badge py={1} px={3} rounded="full" bg={type.color}>
                {type.title}
              </Badge>
            </HStack>
            {date && (
              <Text fontSize="sm" color="gray.700" fontWeight="bold">
                {date}
              </Text>
            )}
          </VStack>

          {chart && chart.data && (
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={chart.data}>
                <XAxis minTickGap={40} dataKey="name" />
                <Tooltip />
                <CartesianGrid stroke="#f5f5f5" />
                <Line type="linear" dataKey="completions" stroke="#805AD5" yAxisId={0} />
                <Line type="linear" dataKey="impressions" stroke="#38A169" yAxisId={0} />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Stack>
      </Box>
    )
  }

  const renderSection = (item = {}) => {
    const { title, lessons = [] } = item

    return (
      <Box key={title} p={3}>
        <Text fontSize="xl" fontWeight="black">
          {title}
        </Text>
        <Divider />
        <Stack mt={2} spacing={2}>
          {lessons.map(renderLesson)}
        </Stack>
      </Box>
    )
  }

  return (
    <Box maxW="xl" mx="auto" mt={5} p={5}>
      <Text fontWeight="bold" fontSize="2xl">
        {course.title}
      </Text>
      <Divider />
      <VStack py={5}>
        <HStack>
          <Text>Total Bookings:</Text>
          <Text fontWeight="bold">{course.bookings_count}</Text>
        </HStack>
        <Divider />

        <Stack spacing={0} w="100%">
          <Text fontWeight="medium" textAlign="left">
            Cohort Activity
          </Text>

          {course && course.chart && (
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={course.chart.data}>
                <XAxis minTickGap={40} dataKey="name" />
                <Tooltip />
                <CartesianGrid stroke="#f5f5f5" />
                <Line type="linear" dataKey="completions" stroke="#805AD5" yAxisId={0} />
                <Line type="linear" dataKey="impressions" stroke="#38A169" yAxisId={0} />
                <Line type="linear" dataKey="users" stroke="#3182CE" yAxisId={0} />
              </LineChart>
            </ResponsiveContainer>
          )}
          <Button w="100%" onClick={createExport} isLoading={loading.exporting}>
            Export All Activity
          </Button>
        </Stack>

        <Divider />
        <Stack w="100%">
          <Text fontWeight="medium" textAlign="left">
            Dropoff Export
          </Text>
          <HStack>
            <Input ref={lessonARef} placeholder="From Lesson" />
            <Input ref={lessonBRef} placeholder="To Lesson" />
          </HStack>
          <Button onClick={createDropoffExport} isLoading={loading.dropoff}>
            Export Dropoff
          </Button>
        </Stack>
      </VStack>

      <Divider />

      {contract && (
        <Stack spacing={4} py={5}>
          <Text fontWeight="bold">Contract Data</Text>

          <Box>
            <HStack>
              <Text fontWeight="medium">Merkle Root On Contract</Text>

              {contract.merkleRoot !== cohort.merkleRoot && (
                <Text textAlign="left" fontSize="sm" color="gray.700">
                  ⚠️ Out Of Sync
                </Text>
              )}
              {contract.merkleRoot === cohort.merkleRoot && (
                <Text textAlign="left" fontSize="sm" color="gray.700">
                  ✅ In Sync
                </Text>
              )}
            </HStack>
            <Text textAlign="left" fontSize="xs">
              {contract.merkleRoot}
            </Text>
          </Box>
          <Box>
            <HStack>
              <Text fontWeight="medium">Merkle Root On Server</Text>
              {cohort.merkleRoot && contract.merkleRoot !== cohort.merkleRoot && (
                <Button colorScheme="blue" onClick={onCopy} size="sm">
                  {hasCopied ? 'Copied' : 'Copy'}
                </Button>
              )}
            </HStack>
            <Text textAlign="left" fontSize="xs">
              {cohort.merkleRoot}
            </Text>
          </Box>
          <Box>
            <HStack>
              <Text fontWeight="medium">Tokens Minted</Text>
            </HStack>
            <Text textAlign="left" fontSize="xs">
              {contract.tokenMinted.toString()}
            </Text>
          </Box>
          <Box>
            <HStack>
              <Text fontWeight="medium">Tokens Limit</Text>
            </HStack>
            <Text textAlign="left" fontSize="xs">
              {contract.limit.toString()}
            </Text>
          </Box>
          <Button isLoading={loading.merkle} onClick={updateMerkleRoot}>
            Recalcuate Merkle Root
          </Button>
        </Stack>
      )}
      <Divider />

      <Box py={5}>
        {/* <Button
          fontWeight="bold"
          bg="gray.500"
          color="white"
          h="50px"
          fontSize="lg"
          style={{ text: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={() => setCreateLessonModal(true)}
        >
          <Icon mr={2} name="add" />
          Create Lesson
        </Button> */}

        {course.sections && <Stack spacing={4}>{course.sections.map(renderSection)}</Stack>}

        {(!course.sections || course.sections.length === 0) && <Text>No sections</Text>}
      </Box>

      {loading.init && (
        <Box d="flex" my={4} justifyContent="center">
          <Spinner color="purple.500" />
        </Box>
      )}
      <Divider />
    </Box>
  )
}

export default Upcoming
