import React, { useState, useEffect } from 'react'

import './App.css'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'
import { Text, Box, Stack } from '@chakra-ui/react'
import Amplify from 'aws-amplify'
import { detect } from 'detect-browser'
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom'

import awsconfig from './aws-exports'
import Cohort from './Cohort'
import Companies from './Companies'
import Course from './Course'
import Courses from './Courses'
import Goals from './Goals'
import Header from './Header'
import Jobs from './Jobs'
import Lesson from './Lesson'

Amplify.configure(awsconfig)

function App() {
  const browser = detect()
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  return authState === AuthState.SignedIn && user ? (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/courses">
            <Courses />
          </Route>
          <Route exact path="/goals">
            <Goals />
          </Route>
          <Route exact path="/courses/:course_id">
            <Course />
          </Route>

          <Route
            path="/courses/:course_id/lessons/:lesson_id"
            component={({ location }) => (
              <Redirect
                to={{
                  ...location,
                  pathname: location.pathname.replace(/courses/, 'projects'),
                }}
              />
            )}
          />
          <Route
            path="/courses/:course_id/cohorts/:cohort_id"
            component={({ location }) => (
              <Redirect
                to={{
                  ...location,
                  pathname: location.pathname.replace(/courses/, 'projects'),
                }}
              />
            )}
          />

          <Route exact path="/companies">
            <Companies />
          </Route>
          <Route path="/projects/:course_id/cohorts/:cohort_id">
            <Cohort />
          </Route>
          <Route path="/projects/:course_id/lessons/:lesson_id">
            <Lesson />
          </Route>
          <Route path="/jobs" render={props => <Jobs {...props} />} />
          <Redirect exact to="/courses" />
        </Switch>
      </Router>

      <Box d="flex" justifyContent="center" p={[3, null, 5]} bg="white">
        <Stack alignItems="center">
          <Stack spacing={5} isInline>
            <Text fontWeight="medium" fontSize="sm">
              <a
                href="https://zipschool.webflow.io/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms Of Service
              </a>
            </Text>
            <Text fontWeight="medium" fontSize="sm">
              <a
                href="https://zipschool.webflow.io/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </Text>
          </Stack>
          <Stack isInline alignItems="center">
            <Text color="gray.500" fontWeight="normal" fontSize="sm">
              © 2021{' '}
              <a href="https://zipschool.com?ref=app" rel="noopener noreferrer" target="_blank">
                Zip Education Inc.
              </a>
            </Text>
            <Text color="gray.400" fontSize="xs">
              v{process.env.REACT_APP_VERSION}
            </Text>
            {browser && (
              <Text color="gray.400" fontSize="xs">
                {browser.os} | {browser.name}
              </Text>
            )}
          </Stack>
        </Stack>
      </Box>
    </div>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in">
        <div slot="secondary-footer-content" />
      </AmplifySignIn>
    </AmplifyAuthenticator>
  )
}

export default App
