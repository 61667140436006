import React, { useState, useEffect, useCallback } from 'react'

import {
  useToast,
  Text,
  Stack,
  Box,
  Divider,
  Spinner,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  ModalOverlay,
  ModalContent,
  HStack,
  ButtonGroup,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import actions from '../actions'
import { showErrorToast } from '../utils'

function UserModal() {
  const [loading, setLoading] = useState({})
  const [companies, setCompanies] = useState([])
  const [createIntroModal, setCreateIntroModal] = useState(false)

  const [user, setUser] = useState({})
  const { user_id } = useParams()

  const toast = useToast()

  const fetchUser = useCallback(
    async silent => {
      if (!silent) {
        setLoading(prevLoading => {
          prevLoading.init = true
          return { ...prevLoading }
        })
      }

      try {
        const { user: _user, companies: c = [] } = await actions.fetchUser(user_id)
        setUser(_user)
        setCompanies(c)
      } catch (err) {
        showErrorToast(toast, err.message)
      }

      setLoading(prevLoading => {
        prevLoading.init = false
        return { ...prevLoading }
      })
    },
    [toast, user_id]
  )

  const updateBuildspaceRank = useCallback(
    async rank => {
      setLoading(prevLoading => {
        prevLoading[rank] = true
        return { ...prevLoading }
      })

      try {
        await actions.updateUser(user_id, {
          action: 'bs_rank',
          data: { rank },
        })
        await fetchUser(true)
      } catch (err) {
        showErrorToast(toast, err.message)
      }

      setLoading(prevLoading => {
        prevLoading[rank] = false
        return { ...prevLoading }
      })
    },
    [toast, fetchUser, user_id]
  )

  const updateIntro = useCallback(
    async (intro_id, status) => {
      setLoading(prevLoading => {
        prevLoading[intro_id] = true
        return { ...prevLoading }
      })

      try {
        await actions.updateIntro(user_id, intro_id, {
          action: 'status',
          data: { status },
        })
        await fetchUser(true)
      } catch (err) {
        showErrorToast(toast, err.message)
      }

      setLoading(prevLoading => {
        prevLoading[intro_id] = false
        return { ...prevLoading }
      })
    },
    [toast, user_id, fetchUser]
  )

  const createIntro = useCallback(
    async company_id => {
      setLoading(prevLoading => {
        prevLoading.intro = true
        prevLoading.company_id = true
        return { ...prevLoading }
      })

      try {
        await actions.createIntro(user_id, company_id)
        await fetchUser(true)
        setCreateIntroModal(false)
      } catch (err) {
        showErrorToast(toast, err.message)
      }

      setLoading(prevLoading => {
        prevLoading.intro = false
        prevLoading.company_id = false
        return { ...prevLoading }
      })
    },
    [toast, user_id, fetchUser]
  )

  const renderCreateIntroModal = () => (
    <Modal
      size="lg"
      isOpen={createIntroModal}
      onClose={() => setCreateIntroModal(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Intro</ModalHeader>
        <ModalBody>
          <Stack p={2} maxH="500px" overflow="scroll" overflowX="hidden">
            {companies.map(c => (
              <Button
                minH="50px"
                key={c.id}
                isLoading={loading[c.id]}
                isDisabled={loading.intro}
                onClick={() => createIntro(c.id)}
              >
                {c.name}
              </Button>
            ))}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            fontWeight="medium"
            mr={3}
            onClick={() => setCreateIntroModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return (
    <Stack borderRadius="2px" pb={4}>
      {renderCreateIntroModal()}
      <Text fontSize="lg" fontWeight="bold">
        {user.first_name} {user.last_name}
      </Text>
      <Box>
        {loading.init ? (
          <Spinner />
        ) : (
          <Stack spacing={8}>
            <Box>
              <Text fontWeight="medium">Notes</Text>
              <Text fontSize="sm">{user.notes || 'No notes'}</Text>
            </Box>
            <Box>
              <Text fontWeight="medium">Liked Companies</Text>
              {user.companies &&
                user.companies.map(c => (
                  <Text key={c} fontSize="sm">
                    {c}
                  </Text>
                ))}
            </Box>
            <Box>
              <Text fontWeight="medium">Roles</Text>
              {user.roles &&
                user.roles.map(r => (
                  <Text key={r} fontSize="sm">
                    {r}
                  </Text>
                ))}
            </Box>
            <Box>
              <Text fontWeight="medium">Socials</Text>
              <Text fontSize="sm">LinkedIn: {user.linkedin || 'N/A'}</Text>
              <Text fontSize="sm">GitHub: {user.github || 'N/A'}</Text>
              <Text fontSize="sm">Website: {user.website || 'N/A'}</Text>
              <Text fontSize="sm">Twitter: {user.twitter || 'N/A'}</Text>
            </Box>
            <Box>
              <Text fontWeight="medium">Buildspace Rank</Text>
              <ButtonGroup size="lg" isAttached>
                <Button
                  w="33%"
                  bg={user.rank === 'low' ? 'red.500' : 'white'}
                  color={user.rank === 'low' ? 'white' : 'black'}
                  colorScheme="red"
                  isLoading={loading.low}
                  borderWidth="1px"
                  onClick={() => updateBuildspaceRank('low')}
                >
                  Low
                </Button>
                <Button
                  w="33%"
                  bg={user.rank === 'med' ? 'yellow.500' : 'white'}
                  color={user.rank === 'med' ? 'white' : 'black'}
                  colorScheme="yellow"
                  isLoading={loading.med}
                  borderWidth="1px"
                  onClick={() => updateBuildspaceRank('med')}
                >
                  Med
                </Button>
                <Button
                  w="33%"
                  bg={user.rank === 'high' ? 'green.500' : 'white'}
                  color={user.rank === 'high' ? 'white' : 'black'}
                  colorScheme="green"
                  isLoading={loading.high}
                  borderWidth="1px"
                  onClick={() => updateBuildspaceRank('high')}
                >
                  High
                </Button>
              </ButtonGroup>
            </Box>
            <Divider />
            <Box>
              <Text fontWeight="bold">Introductions</Text>
              {user.introductions && (
                <Stack alignItems="center">
                  {user.introductions.map(i => {
                    return (
                      <HStack key={i.id}>
                        <Text>{i.company}</Text>
                        <ButtonGroup size="lg" isAttached>
                          <Button
                            bg={i.status === 'INTERVIEWING' ? 'yellow.500' : 'white'}
                            color={i.status === 'INTERVIEWING' ? 'white' : 'black'}
                            colorScheme="yellow"
                            isLoading={loading[i.id]}
                            borderWidth="1px"
                            onClick={() => updateIntro(i.id, 'INTERVIEWING')}
                          >
                            Interviewing
                          </Button>
                          <Button
                            bg={i.status === 'HIRED' ? 'green.500' : 'white'}
                            color={i.status === 'HIRED' ? 'white' : 'black'}
                            colorScheme="green"
                            isLoading={loading[i.id]}
                            borderWidth="1px"
                            onClick={() => updateIntro(i.id, 'HIRED')}
                          >
                            Hired
                          </Button>
                          <Button
                            bg={i.status === 'DENIED' ? 'red.500' : 'white'}
                            color={i.status === 'DENIED' ? 'white' : 'black'}
                            colorScheme="red"
                            isLoading={loading[i.id]}
                            borderWidth="1px"
                            onClick={() => updateIntro(i.id, 'DENIED')}
                          >
                            Denied
                          </Button>
                          <Button
                            bg={i.status === 'GHOSTED' ? 'purple.500' : 'white'}
                            color={i.status === 'GHOSTED' ? 'white' : 'black'}
                            colorScheme="purple"
                            isLoading={loading[i.id]}
                            borderWidth="1px"
                            onClick={() => updateIntro(i.id, 'GHOSTED')}
                          >
                            Ghosted
                          </Button>
                          <Button
                            bg={i.status === 'PASSED' ? 'gray.500' : 'white'}
                            color={i.status === 'PASSED' ? 'white' : 'black'}
                            colorScheme="gray"
                            isLoading={loading[i.id]}
                            borderWidth="1px"
                            onClick={() => updateIntro(i.id, 'PASSED')}
                          >
                            Passed
                          </Button>
                        </ButtonGroup>
                      </HStack>
                    )
                  })}
                </Stack>
              )}
              <Button mt={2} onClick={() => setCreateIntroModal(true)}>
                Create Introduction +
              </Button>
            </Box>
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

export default UserModal
